.hideOnMobile {
    display: block;
    background-color: #00000077;
  }
  
  @media (max-width: 767px) {
    .hideOnMobile {
      display: none;
    }
  }
  