.ticket {
    backface-visibility: hidden;
    transform: translateZ(0);
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    margin-top: -65px;
    margin-left: -144px;
    position: absolute;
    display: inline-block;
    padding: 15px 25px;
    background-position: center center, bottom left, bottom right, top right, top left;
    background-size: cover, 51% 51%, 51% 51%, 51% 51%, 51% 51%;
    background-repeat: no-repeat;
    border-width: 0 4px;
    border-color: transparent;
    border-style: solid;
    -webkit-filter: drop-shadow(hsla(0, 0%, 0%, 0.55) 1px 1px 2px);
}

.ticket > div {
    width: 230px;
    height: 100px;
    box-sizing: border-box;
    position:relative;
     border-color: var(--ticket-border-color);
    border-style: solid;
    border-width: 2px;
    border-radius:5px;
  	text-align:center;
  	font: 1.8em/1 Impact;
  	text-transform:uppercase;
  	padding:20px;
  	font-stretch: extra-expanded; 	
}

.ticket > div:before,
.ticket > div:after {
	  content: attr(data-number);
  	font: .625em/1 'Courier New';
  	position: absolute;
  	width: 96px;
  	line-height: 28px;
  	transform: rotate(90deg) translateZ(0) ;
  	transform-origin: 0% 0%;
  	text-align:center;
  	display: block; 	
  	top: 0;
  	 border-color: var(--ticket-border-color);
  	border-style: solid;
  	border-width: 2px 0 0;
}
.ticket > div:before {
	left: 28px;
}

.ticket > div:after {
	transform-origin: 100% 0%;
	right: 28px;
	transform: rotate(-90deg) translateZ(0);
}

.radek {
  font-size: 1.2rem;
}

html, body { height:100%; }


