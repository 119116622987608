@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400..800&family=Cairo+Play:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Patrick+Hand+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Patrick+Hand&family=Patrick+Hand+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200..800&family=Oxanium:wght@200..800&family=Saira:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Baloo Bhaina 2", serif !important;
  }

  .CallB {
    font-family: "Baloo Bhaina 2", serif !important;
    font-weight: 800 !important;
    font-size: 2.6rem !important;
  }

  .CallB2 {
    font-family: "Baloo Bhaina 2", serif !important;
    font-size: 2rem !important;
    font-weight: 400;
    color: "#b2b2b2"
  }

.uploadText {
    font-family: "Prompt", sans-serif;
    font-size: 1.30rem;
}

.subText {
    font-family: "Prompt", sans-serif;
    font-size: 1rem;
    color: "#777B8E" !important;
}

CustomInput {
    font-family: "Gemunu Libre", serif !important;
    font-weight: 600 !important;
}

textarea {
  font-family: "Gemunu Libre", serif !important;
  font-weight: 600 !important;
}


.TEST {
    font-family: "Patrick Hand", sans-serif !important;
}

.MuiTooltip-tooltip {
    border: none !important;
    box-shadow: none !important;
    background: #f9f7c0 !important;
    transform: translate(50%) !important;
  }

  .disabledButton {

    background-color: #ccc;
  
    color: #666;
    font-family: "Gemunu Libre", serif !important;
    font-weight: 600 !important;
  
    cursor: not-allowed !important;
    pointer-events: none !important;
    background-color: #00000060 !important;
    font-weight: 100 !important;
    color: black !important;
  
  }


  .disabledButton2 {

    background-color: #ccc;
    color: #666;
    font-family: "Gemunu Libre", serif !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    background-color: #00000060 !important;
    font-weight: 600 !important;
    color: black !important;
  
  }

  .myDate {
    font-family: "Gemunu Libre", serif !important;
    font-weight: 600 !important;
      background-color: #00000060 !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
    color: #bdadff !important;
  
  }


  .MuiInput-input css-1gw9vc6-JoyInput-input {
    cursor: not-allowed !important;

  }


  .custom-input {
    background-color: #1a1d1f40;
    border-radius: 7px;
    padding: 10px 12px;
    
    color: var(--text-color, #ffffff);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-image: linear-gradient(to right, rgb(103, 69, 237), rgb(221, 57, 253)) 1;
    border-style: solid;
    transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-input.error {
    color: #ffadad;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #ff3b3b, #ff8a3b) 1;
  }
  
  .custom-input:hover {
    border-color: rgba(255, 255, 255, 0.6);
  }
  
  .custom-input input {
    color: inherit;
    background: none;
  }
  
  .custom-input input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  
  .custom-input input[type="number"] {
    -moz-appearance: input;
    -webkit-appearance: none;
    appearance: input;
  }
  
  .custom-input input[type="number"]::-webkit-outer-spin-button,
  .custom-input input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
  
  .custom-input input:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  
  .custom-input.Mui-disabled {
    color: rgba(255, 255, 255, 0.5);
    background-color: #2a2d2e;
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  button {
    border-radius: 7px !important;
    font-weight: 600 !important;
  }

  a {
    color: #dd39fd;
    text-decoration: underline;
  }
  
  a:visited,
  a:hover,
  a:active {
    color: #dd39fd;
    text-decoration: underline;
  }