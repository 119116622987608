@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  background-color: #070B24;
}

.dashboard {
  font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 90vh; /* Komponenta zabere minimálně celou výšku obrazovky */
}

.dashboard-header {
  padding: 30px 0px 20px 0px;
  z-index: 2; /* Zajistí, že hlavička bude nad modrým pozadím */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dashboard-nav {
  display: flex;
  background-color: transparent; /* Modrá barva pozadí pro navigaci */
  padding: 0 10px; /* Padding pro navigaci */
}

.dashboard-nav span {
  text-decoration: none;
  color: white;
  padding: 12px 25px;
  margin-bottom: 10px;
  border-radius: 9px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-nav span:hover,
.dashboard-nav span.active {
  background-color: #0000001e; 
  cursor: pointer;
}

.dashboard-content-border {
  border-radius: 12px; /* Nastaví velikost zaoblení rohů */
  background: linear-gradient(35deg, #6745ed, #DD39FD);
  background-color: white; 

  height: auto !important;
  z-index: 1; /* Zajistí, že obsah bude nad modrým pozadím */
}

.dashboard-content {
  background-color: #1d1933; /* Světlé pozadí pro obsah */
  border: 3px solid transparent; /* Nastaví prostor pro rámeček */
  margin:3px;
  border-radius: 10px;
  padding: 2px;
  overflow: auto;
  flex-grow: 1; /* Obsah zabere zbytek prostoru */
  position: relative; /* Nastavení pro překrytí modré plochy */
  z-index: 1; /* Zajistí, že obsah bude nad modrým pozadím */
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.411); /* Zmírněný stín pro lepší oddělení obsahu od pozadí */
}

.dashboard-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 33.33vh; /* Nastaví výšku modrého pozadí na jednu třetinu výšky viewportu */
  background: rgb(221,57,253);
  background: linear-gradient(135deg, #dd39fd 0%, #6745ed 52%, #3f206e 100%); 
  z-index: 0; /* Zajistí, že bude pod obsahem a navigací */
}

/* Nově přidané styly pro responzivní chování */
.menu-button {
    display: none; /* Skryje tlačítko menu na desktopu */
  }
  
  @media (max-width: 800px) {

    .dashboard-nav {
        display: none; /* Zajišťuje, že menu je skryto */
        position: absolute;
        top: 98px !important; /* Nastavení, kde má menu začínat */
        width: 98% !important;
        left: -10px;
        flex-direction: column;
        background-color: #151a38fb;
        z-index: 15;
        border-radius: 12px;
        box-shadow: #070B24;
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
    }

    .dashboard-nav span {
        margin-left: 0px !important;
    }

    .dashboard-header {
      position: relative; /* Zajišťuje referenční bod pro absolutní pozicování */
      height: 80px; /* Nastavuje výšku hlavičky */
    }
  
    .menu-button {
      display: block !important; /* Zobrazuje tlačítko menu */
      position: absolute;
      padding-bottom: 0px !important;
      padding-top: 7px !important;
      border-radius: 12px !important;
      font-size: 30px !important;
      left: 10px; /* Umístění vlevo */
      top: 0px; /* Umístění s mírným odsazením od horní části */
      z-index: 20; /* Zajišťuje, že tlačítko je nad ostatními prvky */
      border: solid 2px #fff !important;
      color: #fff !important;
    }
  
    .dashboard-logo {
      width: 190px; /* Zvětšení velikosti loga */
      position: absolute;
      top: 25px !important; /* Nastavuje vertikální pozici loga */
      left: 50%; /* Centrování loga */
      transform: translateX(-50%); /* Přesné centrování */
      z-index: 10; /* Zajišťuje viditelnost loga */
    }
  
    .dashboard-nav.open {
      display: flex; /* Zobrazí menu po kliknutí */
      position: absolute;
      top: 80px; /* Začíná pod hlavičkou */
      width: 100%;
      z-index: 15; /* Zajišťuje, že menu je pod tlačítkem menu */
    }

    .dashboard-nav.closed {
        display: none; /* Ujistíme se, že je menu skryto, pokud je stav closed */
    }
  }
  