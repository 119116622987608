input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.myNumberInput::-webkit-inner-spin-button,
.myNumberInput::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}